export const queryParamsWhitelist = new Set([
	'currency',
	'metaSiteId',
	'isqa',
	'experiments',
	'experimentsoff',
	'suppressbi',
	'sampleratio',
	'hot',
	'viewerPlatformAppSources',
	'wixCodeForceKibanaReport',
	'controllersUrlOverride',
	'debug',
	'petri_ovr',
	'iswixsite',
	'showMobileView',
	'lang',
	'ssrDebug',
	'wixAdsOverlay',
	'ssrIndicator',
	'siteRevision',
	'branchId',
	'forceThunderbolt',
	'editor-elements-override',
	'widgetsUrlOverride',
	'viewerPlatformOverrides',
	'overridePlatformBaseUrls',
	'thunderboltTag',
	'tpasUrlOverride',
	'tpasWorkerUrlOverride',
	'disableHtmlEmbeds',
	'suricateTunnelId',
	'inBizMgr',
])
